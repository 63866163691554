
import {
  Component,
  Vue
} from 'vue-property-decorator'
import ClientService from './services/client-service'
import ClientModal from './components/ClientModal.vue'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'

  @Component({
    name: 'Clients',
    components: {
      ClientModal
    }
  })
export default class Clients extends Vue {
    protected perPage = 10
    protected pageOptions: any = [10, 25, 50, 100]
    protected currentPage = 1
    protected searchTable = null
    protected buttonTabString = null
    private fields = [{
      key: 'index',
      label: '#',
      thStyle: {
        width: '3%'
      },
      class: 'text-center'
    },
    {
      key: 'name',
      thStyle: {
        width: '20%'
      }
    },
    {
      key: 'code',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'email',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'number',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'registration_no',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'version',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'created_date',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '10%'
      },
      class: 'text-center'
    }
    ]

    private clients: any = []

    get rows () {
      return this.clients.length
    }

    created () {
      this.boot()
    }

    protected boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateClients()
    }

    public openModal () {
      (this.$refs.ClientInfoModal as any).open()
    }

    public closeModal () {
      (this.$refs.ClientInfoModal as any).close()
    }

    public async populateClients () {
      const response = await ClientService.clients()
      this.clients = response.data
    }

    private edit (id: any) {
      /* this.$router.push({
        name: 'ClientRegister',
        params: {
          id: id
        }
      }) */
      (this.$refs.ClientInfoModal as any).populateClient(id);
      (this.$refs.ClientInfoModal as any).open()
    }

    public deleteClient (item: any) {
      const msg = `Do you want to delete ${item.name} ?`
      if (confirm(msg) === true) {
        ClientService.delete(item.id).then((response) => {
          // AppModule.message(response.data.message)
          this.clients = []
          this.populateClients()
        }).catch(error => {
          console.log(error)
        })
      }
    }
}

